import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import {
  COOKIE_BANNER_DISMISSED,
  getCookie,
  setCookieBannerDismissed,
  setCookieConsent,
} from '../../utils'
import { BottomSheet, Button } from '../'
import styles from './CookieBanner.module.scss'

const CookieBanner = () => {
  // Don't flash the banner up if the user has previously accepted cookies
  const [hideBanner, setHideBanner] = React.useState(true)
  const router = useRouter()

  React.useEffect(() => {
    if (router.pathname === '/cookies') {
      setHideBanner(true)
      return
    }
    setHideBanner(getCookie(COOKIE_BANNER_DISMISSED) === 'true')
  }, [hideBanner, router.pathname])

  if (hideBanner) return null

  const setCookies = async (value: 'true' | 'false') => {
    await setCookieConsent(value)
    setCookieBannerDismissed()
    setHideBanner(true)
  }

  return (
    <BottomSheet contentClassName={styles.content}>
      <div className={styles.text}>
        <Title is="h2">Can we use optional cookies?</Title>
        <Text>
          We're not talking about the crunchy, tasty kind. These cookies help us
          give you a better experience. We won’t turn them on unless you accept.
          Want to know more or adjust your preferences? Here's our{' '}
          <Link href="/cookies">
            <a
              {...buildActionAnalyticsAttributes('cookie-notice')}
              onClick={() => setHideBanner(true)}
            >
              cookie notice
            </a>
          </Link>
          .
        </Text>
      </div>

      <div className={styles['button-group']}>
        <Button
          theme="primary"
          onClick={() => setCookies('true')}
          data-testid="accept-cookies"
          fullWidth
          {...buildActionAnalyticsAttributes('accept-all')}
        >
          Accept all
        </Button>
        <Button
          theme="primary"
          onClick={() => setCookies('false')}
          data-testid="decline-cookies"
          fullWidth
          {...buildActionAnalyticsAttributes('decline-all')}
        >
          Decline all
        </Button>
        <Button
          theme="secondary"
          data-testid="cookie-prefs"
          onClick={async () => {
            setHideBanner(true)
            await router.push('/cookies')
          }}
          fullWidth
          className={styles['preferences-button']}
          {...buildActionAnalyticsAttributes('preferences')}
        >
          Cookie settings
        </Button>
      </div>
    </BottomSheet>
  )
}

export default CookieBanner
