import cx from 'classnames'
import React from 'react'

import Spacer from '../spacer'
import { filterSpacingProps } from '../spacer/utils'
import styles from './Switch.module.scss'

export type Props = {
  id: string
  checked: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
}

export default function Switch(props: Props) {
  const { id, checked, disabled, onChange, className, ...otherProps } = props

  const { spacingProps } = filterSpacingProps(props)

  return (
    <Spacer
      is="label"
      className={cx(styles.switch, className)}
      {...spacingProps}
    >
      <input
        type="checkbox"
        checked={checked ?? undefined}
        className={styles.hiddenCheckbox}
        disabled={disabled}
        onChange={onChange}
        id={id}
        {...otherProps}
      />
      <span aria-hidden="true" className={styles.track}>
        <span className={styles.thumb}></span>
      </span>
    </Spacer>
  )
}
