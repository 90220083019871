import cx from 'classnames'
import { useRouter } from 'next/router'
import * as React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { ReferralContext } from '../../../providers'
import questionmarkIcon from '../../../public/images/icons/questionmark.circle.svg'
import toyIcon from '../../../public/images/icons/toy.svg'
import bba from '../../../public/images/trust/bba.png'
import cass from '../../../public/images/trust/cass.svg'
import fscs from '../../../public/images/trust/fscs.svg'
import { NUMBER_OF_CUSTOMERS_MILLIONS, getCDNSrc } from '../../../utils'
import { AppStoreButton, Button } from '../../'
import styles from './MultipleBlockBanner.module.scss'

const Block = ({
  iconSrc,
  title,
  text,
  buttonText,
  buttonHref,
  openInNewTab,
  analyticsName,
  shouldDisplay = true,
  className,
}: {
  iconSrc: string
  title: string
  text: string
  buttonText: string
  buttonHref: string
  openInNewTab?: boolean
  analyticsName: string
  shouldDisplay?: boolean
  className?: string
}) => {
  const router = useRouter()

  return shouldDisplay ? (
    <article className={cx([styles.block, className])}>
      <Title is="h2" className={styles.title}>
        <img className={styles.icon} src={iconSrc} alt="" />
        {title}
      </Title>
      {text.split('\n').map((line) => (
        <Text key={line} className={styles.text}>
          {line}
        </Text>
      ))}
      <Button
        theme="primary"
        className={styles.button}
        onClick={async () => await router.push(buttonHref)}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        {...buildActionAnalyticsAttributes(analyticsName)}
      >
        {buttonText}
      </Button>
    </article>
  ) : null
}

const MultipleBlockBanner = () => {
  const { referralData, maxSavingsRate } = React.useContext(ReferralContext)
  const router = useRouter()

  return (
    <div className={styles.inner}>
      <Block
        iconSrc={getCDNSrc(toyIcon)}
        title="Make your money more Monzo"
        text={`More than ${NUMBER_OF_CUSTOMERS_MILLIONS} million customers use our hot coral cards to spend, save and manage their money. ${
          maxSavingsRate
            ? `Earn up to ${maxSavingsRate}% AER on a 6-month fixed term savings account.`
            : ''
        }\nUK residents only. Ts&Cs apply.`}
        buttonText="Find out more"
        buttonHref="https://monzo.com/shared-tabs-more/"
        openInNewTab={true}
        analyticsName="banner-discover"
        className={referralData ? 'desktop-only' : 'mobile-only'}
      />
      <Block
        iconSrc={getCDNSrc(questionmarkIcon)}
        title="Frequently asked questions"
        text="New to Shared Tabs and got a question? Head over to our FAQs, where we've answered the most popular ones."
        buttonText="See FAQs"
        buttonHref="/faq"
        analyticsName="banner-faq"
        shouldDisplay={!router.pathname.includes('faq')}
      />
      <article className={cx([styles['download-block'], 'mobile-only'])}>
        <Title
          is="h2"
          color="white"
          size="large"
          align="center"
          className={styles.title}
        >
          Open a free bank account
        </Title>
        <div className={styles['app-buttons']}>
          <AppStoreButton href="https://monzo.com/download" store="app-store" />
          <AppStoreButton
            href="https://monzo.com/download"
            store="google-play"
          />
        </div>
      </article>

      <div className={cx([styles['trust-grid'], 'mobile-only'])}>
        <img
          src={getCDNSrc(bba)}
          alt="British Bank Awards Best Banking App Winner 2023"
        />
        <img src={getCDNSrc(cass)} alt="Current Account Switch Guarantee" />
        <img
          src={getCDNSrc(fscs)}
          alt="Financial Services Compensation Scheme Protected"
        />
      </div>
    </div>
  )
}

export default MultipleBlockBanner
