import cx from 'classnames'
import Link from 'next/link'
import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'

import { AuthContext } from '../../providers/AuthProvider'
import monzoLogo from '../../public/images/Monzo.svg'
import { getCDNSrc, userLogout } from '../../utils'
import styles from './Header.module.scss'
import { Button } from '..'

export default function Header() {
  const { isSessionUpgraded } = React.useContext(AuthContext)
  return (
    <header className={styles.header}>
      <Link href="https://monzo.com">
        <a
          className={styles.logo}
          {...buildActionAnalyticsAttributes('header-logo')}
        >
          <img src={getCDNSrc(monzoLogo)} alt="Monzo" />
        </a>
      </Link>
      <div
        className={styles['trailing-content']}
        suppressHydrationWarning={true} // We will not know server-side whether the user is logged in
      >
        <Button
          href="https://monzo.com/sign-up/?utm_source=tabsmonzome&utm_medium=website"
          size="small"
          className={cx([styles['mobile-button']])}
          target="_blank"
          theme="primary"
          rel="noopener noreferrer"
          {...buildActionAnalyticsAttributes('header-free-account')}
        >
          Get a free bank account
        </Button>
        {isSessionUpgraded && (
          <Button
            onClick={async () => await userLogout(true)}
            size="small"
            theme="tertiary"
            className={cx([styles['desktop-button'], 'desktop-only'])}
            {...buildActionAnalyticsAttributes('header-logout')}
          >
            Log out
          </Button>
        )}
      </div>
    </header>
  )
}
