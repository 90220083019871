import cx from 'classnames'
import * as React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { ReferralContext } from '../../../providers'
import bba from '../../../public/images/trust/bba.png'
import cass from '../../../public/images/trust/cass.svg'
import fscs from '../../../public/images/trust/fscs.svg'
import { getCDNSrc } from '../../../utils'
import { Button } from '../../'
import styles from './SingleBlockBanner.module.scss'

const SingleBlockBanner = ({
  title,
  text,
  imageSrc,
  buttonText,
  buttonHref,
}: {
  title: string
  text: string
  imageSrc: string
  buttonText: string
  buttonHref: string
}) => {
  const { referralData } = React.useContext(ReferralContext)

  return (
    <div className={cx([styles.inner, referralData ? '' : 'desktop-only'])}>
      <img src={getCDNSrc(imageSrc)} alt="" className={styles.illustration} />
      <Title is="h2" size="small" color="primary" className={styles['title']}>
        {title}
      </Title>
      {text.split('\n').map(
        (line) =>
          line && (
            <Text
              key={line}
              color="primary"
              size="large"
              className={styles['text']}
            >
              {line}
            </Text>
          ),
      )}
      <Button
        theme="secondary"
        href={buttonHref}
        className={styles.button}
        target="_blank"
        rel="noopener noreferrer"
        {...buildActionAnalyticsAttributes('claim-referral')}
      >
        {buttonText}
      </Button>
      <div className={cx([styles['trust-grid'], 'desktop-only'])}>
        <img
          src={getCDNSrc(bba)}
          alt="Best British Bank and Best Business Banking Provider at the British Bank Awards 2023"
        />
        <img src={getCDNSrc(cass)} alt="Current Account Switch Guarantee" />
        <img
          src={getCDNSrc(fscs)}
          alt="Financial Services Compensation Scheme"
        />
      </div>
    </div>
  )
}

export default SingleBlockBanner
