import { getClient } from '@mondough/analytics-v2'
import { Switch, Text } from '@mondough/monzo-ui'

import styles from './SwitchWithLabel.module.scss'
import { SwitchWithLabelProps } from './SwitchWithLabel.types'

const SwitchWithLabel = ({
  id,
  label,
  subLabel,
  checked,
  onChange,
}: SwitchWithLabelProps) => {
  return (
    <div className={styles.switch}>
      <div aria-hidden="true" className={styles['label-container']}>
        <Text className={styles.label}> {label}</Text>
        {subLabel && (
          <Text className={styles.sublabel} color="secondary">
            {subLabel}
          </Text>
        )}
      </div>
      <Switch
        aria-label={`${label}
      ${subLabel}`}
        id={id}
        checked={checked}
        onChange={() => {
          onChange(checked)
          const analyticsClient = getClient()
          if (!analyticsClient) return
          analyticsClient.trackAction({
            name: id,
            type: 'click',
            customParameters: { checked },
          })
        }}
      />
    </div>
  )
}

export default SwitchWithLabel
