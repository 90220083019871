import React, { useEffect, useState } from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import {
  getNotificationPreferences,
  setNotificationPreferences,
} from '../../../utils'
import { BottomSheet, Button, SwitchWithLabel } from '../..'
import styles from './NotificationConsentBanner.module.scss'

const NotificationConsentBanner: React.FC = () => {
  const [activityConsent, setActivityConsent] = useState(true)
  const [showBanner, setShowBanner] = useState(false)

  const [notificationConsentError, setNotificationConsentError] =
    React.useState(false)

  useEffect(() => {
    const fetchNotificationConsent = async () => {
      try {
        const preferences = await getNotificationPreferences()
        setActivityConsent(preferences.notifications_enabled)
        setShowBanner(preferences.using_default)
      } catch {
        // Do nothing
      }
    }
    void fetchNotificationConsent()
  }, [])

  if (!showBanner) return null

  return (
    <BottomSheet
      contentClassName={styles.content}
      overlayZIndex={102}
      containerZIndex={103}
    >
      <div className={styles.text}>
        <Title is="h2">Tab notification settings</Title>
        <Text color="secondary">
          We won't market to you or share your email with anyone. You can always
          change your settings later.
        </Text>
      </div>
      <SwitchWithLabel
        id="tab-notifications"
        label="Tab activity"
        subLabel={`Tap to turn ${activityConsent ? 'off' : 'on'}`}
        checked={activityConsent}
        onChange={() => setActivityConsent((value) => !value)}
      />

      <Button
        theme="primary"
        fullWidth
        onClick={async () => {
          try {
            await setNotificationPreferences(activityConsent)
            setShowBanner(false)
          } catch {
            setNotificationConsentError(true)
          }
        }}
        marginTop="medium"
        className={styles.button}
        {...buildActionAnalyticsAttributes('confirm', {
          enabled: activityConsent,
        })}
      >
        Got it
      </Button>
      {notificationConsentError && (
        <Text size="small" color="destructive">
          Something went wrong. Please try again.
        </Text>
      )}
    </BottomSheet>
  )
}

export default NotificationConsentBanner
